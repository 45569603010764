import { TLevelInfo } from "./types";

export const LEVEL_INFOS: TLevelInfo[] = [
  {
    level: "entry",
    held: "500+",
    discount: "5%",
  },
  {
    level: "advanced",
    held: "1000+",
    discount: "10%",
  },
];
