import {
  Box,
  Divider,
  IconButton,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { LogoLinkWhite } from "src/components/LogoLink";
import { DISCORD_URL, TELEGRAM_URL, TWITTER_URL } from "src/config";
import { useMdOrUp, useXlOrUp, useLgOrUp } from "src/themes";

import { GradientBox } from "../GradientBox";
import { LinkGroup, MOBILE_HEADER_PADDING } from "./LinkGroup";
import { FOOTER_LINK_GROUPS } from "./config";
import discordIcon from "./icons/discord.svg";
import telegramIcon from "./icons/telegram.svg";
import xIcon from "./icons/x.svg";

export const Footer = () => {
  const theme = useTheme();
  const isMdOrUp = useMdOrUp();
  const isLgOrUp = useLgOrUp();
  const isXlOrUp = useXlOrUp();

  return (
    <Box component="footer" px={isMdOrUp ? 2.5 : 1}>
      <GradientBox
        mainColor={theme.palette.primary.main}
        borderColor="#FEAC4E"
        shadeStrength={1}
        px={isXlOrUp ? 27.5 : isLgOrUp ? 18 : isMdOrUp ? 8 : 1.5}
        pt={isXlOrUp ? 15 : isLgOrUp ? 10 : isMdOrUp ? 6 : 4}
      >
        <Stack
          gap={{
            xs: 4,
            md: 8,
            lg: 10,
          }}
          width="100%"
        >
          <Stack
            direction={{ md: "row" }}
            gap={4}
            justifyContent="space-between"
            alignItems="center"
          >
            <LogoLinkWhite />

            <Stack gap={2} direction="row">
              <IconButton href={TWITTER_URL} target="_blank" sx={{ p: 0 }}>
                <Box
                  sx={{
                    ...iconBoxCommonSx,
                    backgroundColor: theme.palette.text.primary,
                  }}
                >
                  <img src={xIcon} alt="x" style={{ width: 20, height: 20 }} />
                </Box>
              </IconButton>

              <IconButton href={TELEGRAM_URL} target="_blank" sx={{ p: 0 }}>
                <Box
                  sx={{
                    ...iconBoxCommonSx,
                    backgroundColor: theme.palette.text.primary,
                  }}
                >
                  <img
                    src={telegramIcon}
                    alt="telegram"
                    style={{ width: 20, height: 20 }}
                  />
                </Box>
              </IconButton>

              <IconButton href={DISCORD_URL} target="_blank" sx={{ p: 0 }}>
                <Box
                  sx={{
                    ...iconBoxCommonSx,
                    backgroundColor: theme.palette.text.primary,
                  }}
                >
                  <img
                    src={discordIcon}
                    alt="discord"
                    style={{ width: 20, height: 20 }}
                  />
                </Box>
              </IconButton>
            </Stack>
          </Stack>

          <Typography
            variant={isXlOrUp ? "h1" : isLgOrUp ? "h2" : "h1"}
            color="text.primary"
            textAlign={isMdOrUp ? "start" : "center"}
            noWrap
          >
            Your Edge in
            <br /> Memecoin Trading
          </Typography>

          <Divider flexItem sx={{ borderColor: "#764529" }} />

          <Stack
            direction={{ md: "row" }}
            gap={2}
            justifyContent="space-between"
          >
            <Stack
              gap={2}
              width="100%"
              alignItems={isMdOrUp ? "flex-start" : "center"}
            >
              <Typography
                variant="body1"
                textAlign={isMdOrUp ? "start" : "center"}
              >
                © PADRE 2024
              </Typography>

              <Typography
                variant="body2"
                color={`${theme.palette.text.secondary}88`}
                textAlign={isMdOrUp ? "start" : "center"}
                sx={{ maxWidth: 330 }}
              >
                By accesing our services, you accept our Terms of Service, and
                that each transaction incurs a fee up to 1%.
              </Typography>
            </Stack>

            <Stack
              direction={{ md: "row" }}
              justifyContent={{ md: "flex-end" }}
              gap={{ xs: 0, md: 8 }}
              mb={{ md: 8 }}
              mt={{ xs: -MOBILE_HEADER_PADDING, md: 0 }}
            >
              {FOOTER_LINK_GROUPS.map((group) => (
                <LinkGroup
                  key={group.items[0]?.name || "unknown"}
                  group={group}
                  wrapperProps={{
                    flex: { md: `0 1 ${LINK_COLUMN_WIDTH}px` },
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </GradientBox>
    </Box>
  );
};

const LINK_COLUMN_WIDTH = 145;

const LINK_ICON_BG_SIZE = 40;
const LINK_ICON_BG_SIZE_MOBILE = 32;

const iconBoxCommonSx: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  width: {
    xs: LINK_ICON_BG_SIZE_MOBILE,
    md: LINK_ICON_BG_SIZE,
  },
  height: {
    xs: LINK_ICON_BG_SIZE_MOBILE,
    md: LINK_ICON_BG_SIZE,
  },
};
